import React from "react";
import Wrapper from "../../components/UI/Wrapper";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";
import Blog from "../blog/Blog";

const BlogPage = () => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Blog`} />
      <Breadcrumb title="Blog" />
      <Blog />
      <Footer />
    </Wrapper>
  );
};

export default BlogPage;
