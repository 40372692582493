import React from "react";
import { Link, Redirect } from "react-router-dom";
import "../../assets/styles/Module/portfolio-details.scss";
import PortfolioData from "../../data/Portfolio/Portfolio.json";

const SinglePortfolio = (props) => {
  const validId = parseInt(props.match.params.id);
  if (!validId) {
    return <Redirect to="/404" />;
  }

  const PortfolioDataObj = {};
  PortfolioData.forEach((post) => {
    if (validId === post.id) {
      PortfolioDataObj.title = post.title ? post.title : "No title given";
      PortfolioDataObj.image = post.image ? post.image : "No Image given";
    }
  });

  return (
    <section className="portfolio__details--section mb-95">
      <div className="container">
        <div className="portfolio__details--thumbnail mb-50">
          <div className="row row-cols-sm-2 row-cols-1">
            <div className="col">
              <img
                className="portfolio__details--thumbnail__img"
                src={PortfolioDataObj.image}
                alt={PortfolioDataObj.title}
              />
            </div>
            <div className="col">
              <div className="portfolio__info--view">
                <h3 className="portfolio__info--subtitle mb-25">
                  Lynch Personal Portfolio
                </h3>
                <ul className="portfolio__info--wrapper mb-20">
                  <li className="portfolio__info--list">
                    <span className="portfolio__info--label">Project :</span>
                    <span className="portfolio__info--value">
                      Personal Portfolio
                    </span>
                  </li>
                  <li className="portfolio__info--list">
                    <span className="portfolio__info--label">Client :</span>
                    <span className="portfolio__info--value">
                      United States
                    </span>
                  </li>
                  <li className="portfolio__info--list">
                    <span className="portfolio__info--label">Services :</span>
                    <span className="portfolio__info--value">
                      Branding, Web Design
                    </span>
                  </li>
                  <li className="portfolio__info--list">
                    <span className="portfolio__info--label">Date :</span>
                    <span className="portfolio__info--value">
                      September 17, 2021
                    </span>
                  </li>
                  <li className="portfolio__info--list">
                    <span className="portfolio__info--label">Website :</span>
                    <span className="portfolio__info--value">
                      Info@example.com
                    </span>
                  </li>
                </ul>
                <Link className="info__preview--btn action__btn" to="#">
                  Preview
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio__details--info">
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio__info--content">
                <div className="section__title mb-25">
                  <span className="section__subtitle gradient__text mb-10">
                    Project Info
                  </span>
                  <h3 className="section__title--text">
                    {PortfolioDataObj.title}
                  </h3>
                </div>
                <p className="portfolio__info--desc">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Blanditiis vitae ut pariatur culpa placeat, beatae iste!
                  Eveniet sint sed ab eligendi consequuntur, aliquid fugiat
                  accusamus harum consectetur blanditiis ex eos alias
                  asperiores. Amet illum saepe voluptatibus minus nesciunt ab
                  eaque! Corrupti alias a itaque voluptatum dolor! Laboriosam
                  sit dolore a?
                </p>
                <p className="portfolio__info--desc">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Architecto temporibus esse qui dolorem mollitia autem magnam
                  dolorum doloribus, reprehenderit ipsam rerum aliquid porro
                  corrupti quaerat!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SinglePortfolio;
