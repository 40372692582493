import React from "react";
import Wrapper from "../../components/UI/Wrapper";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";
import ContactSection from "../../components/Contact/ContactSection";
import Map from "../../components/Contact/Map";

const Contact = () => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Contact Us`} />
      <Breadcrumb title="Contact Us" />
      <ContactSection />
      <Map />
      <Footer />
    </Wrapper>
  );
};

export default Contact;
