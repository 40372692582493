import React, { useState, useEffect } from "react";
import Portfolio from "../../components/Portfolio/Portfolio";
import PortfolioData from "../../data/Portfolio/Portfolio.json";
import imagesloaded from "imagesloaded";
import Isotope from "isotope-layout";
import Fancybox from "../../components/fancybox";

function flatDeep(arr, d = 1) {
  return d > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
        []
      )
    : arr.slice();
}

const PortfolioSection = ({ DarkButtonTextColor }) => {
  // Porject Isotope JS

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const mixCategories = PortfolioData.map((item) =>
      item.category.map((cat) => cat)
    );
    const allCat = flatDeep(mixCategories, Infinity);
    const commonCategories = [...new Set(allCat)];

    setCategories(commonCategories);

    // This for Images
    const masonryList = document.querySelector(".portfolio__grid");
    imagesloaded(masonryList, () => {
      const projectItems = masonryList.querySelectorAll(
        ".portfolio__grid--col"
      );
      let start = 1;
      while (start < projectItems.length) {
        projectItems[start].classList.add("portfolio__grid--col");
        start += 4;
      }
      let Iso = new Isotope(masonryList, {
        itemSelector: ".portfolio__grid--col",
      });

      const filterWrap = document.querySelector(".portfolio__filter");
      const filterItems = document.querySelectorAll(
        ".portfolio__filter button"
      );
      filterItems.forEach((filterItem, index) => {
        filterItem.addEventListener("click", (e) => {
          const filterCate = filterItem.dataset.filter;
          filterWrap.querySelector(".active").classList.remove("active");
          e.target.classList.add("active");
          Iso.arrange({
            filter: filterCate,
          });
        });
      });
    });
  }, []);

  return (
    <section className="portfolio__section mb-75">
      <div className="container">
        <div className="section__title text-center mb-40">
          <span className="section__subtitle gradient__text mb-12">
            Portfolio Your Feedback
          </span>
          <h2 className="section__title--text">My Portfolio</h2>
        </div>
        <div className="portfolio__wrapper">
          <div className="portfolio__filter d-flex justify-content-center mb-50">
            <button
              className={`portfolio__filter--button ${DarkButtonTextColor} active`}
              data-filter="*"
            >
              All
            </button>

            {categories.map((category, indx) => (
              <button
                className={`portfolio__filter--button ${DarkButtonTextColor}`}
                key={indx}
                data-filter={`.${category.toLowerCase()}`}
              >
                {category}
              </button>
            ))}
          </div>
          <Fancybox>
            <div className="row portfolio__grid">
              {PortfolioData.map((portfolio) => (
                <div
                  key={portfolio.id}
                  className={`col-lg-4 col-md-6 col-sm-6 col-6 custom-col portfolio__grid--col ${portfolio.category
                    .join(" ")
                    .toLowerCase()}`}
                >
                  <Portfolio
                    title={portfolio.title}
                    thumb={portfolio.image}
                    subtitle={portfolio.subtitle}
                    description={portfolio.description}
                    id={portfolio.id}
                    DarkButtonTextColor={DarkButtonTextColor}
                  />
                </div>
              ))}
            </div>
          </Fancybox>
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
