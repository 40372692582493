import React from "react";
import CollapseTab from "../../components/Accordion/CollapseTab";
import "../../assets/styles/Module/faq.scss";

const FaqSection = () => {
  return (
    <section className="faq__section mb-100">
      <div className="container">
        <div className="faq__section--inner">
          <div className="faq__title border-bottom text-center">
            <h2 className="faq__title--text">Frequently Asked Questions</h2>
          </div>
          <div className="face__Wrapper">
            <div className="face__step one">
              <div className="row">
                <div className="col-lg-6">
                  <CollapseTab />
                </div>
                <div className="col-lg-6">
                  <CollapseTab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
