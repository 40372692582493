import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./templates/pages/Home";
import Services from "./templates/pages/ServicesPage";
import AboutUs from "./templates/pages/AboutUs";
import Portfolio from "./templates/pages/Portfolio";
import Contact from "./templates/pages/Contact";
import BlogPage from "./templates/pages/BlogPage";
import BlogDetails from "./templates/pages/BlogDetails";
import PortfolioDetails from "./templates/pages/PortfolioDetails";
import ServicesDetails from "./templates/pages/ServicesDetails";
import Faq from "./templates/pages/Faq";
import ErrorPage from "./templates/pages/ErrorPage";
import ScrollToTop from "./components/UI/ScrollToTop";
import ScrollToTopPage from "react-scroll-to-top";

import DarkHome from "./templates/pages/darkVersion/DarkHome";
import DarkAboutUs from "./templates/pages/darkVersion/DarkAboutUs";
import DarkContact from "./templates/pages/darkVersion/DarkContact";
import DarkServicesPage from "./templates/pages/darkVersion/DarkServicesPage";
import DarkServicesDetails from "./templates/pages/darkVersion/DarkServicesDetails";
import DarkPortfolio from "./templates/pages/darkVersion/DarkPortfolio";
import DarkPortfolioDetails from "./templates/pages/darkVersion/DarkPortfolioDetails";
import DarkBlogPage from "./templates/pages/darkVersion/DarkBlogPage";
import DarkBlogDetails from "./templates/pages/darkVersion/DarkBlogDetails";
import DarkErrorPage from "./templates/pages/darkVersion/DarkErrorPage";
import DarkFaq from "./templates/pages/darkVersion/DarkFaq";

function App() {
  return (
    <Router>
      <ScrollToTopPage smooth color="#e62e83" />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home-dark" exact component={DarkHome} />

        <Route path="/about-us" component={AboutUs} />
        <Route path="/dark-about" component={DarkAboutUs} />

        <Route path="/service" component={Services} />
        <Route path="/dark-service" component={DarkServicesPage} />
        <Route
          path="/service-details/:id"
          render={(props) => <ServicesDetails {...props} />}
        />
        <Route
          path="/dark-service-details/:id"
          render={(props) => <DarkServicesDetails {...props} />}
        />

        <Route path="/portfolio" component={Portfolio} />
        <Route path="/dark-portfolio" component={DarkPortfolio} />
        <Route
          path="/portfolio-details/:id"
          render={(props) => <PortfolioDetails {...props} />}
        />
        <Route
          path="/dark-portfolio-details/:id"
          render={(props) => <DarkPortfolioDetails {...props} />}
        />

        <Route path="/blog" component={BlogPage} />
        <Route path="/dark-blog" component={DarkBlogPage} />
        <Route
          path="/blog-post/:id"
          render={(props) => <BlogDetails {...props} />}
        />
        <Route
          path="/dark-blog-post/:id"
          render={(props) => <DarkBlogDetails {...props} />}
        />

        <Route path="/contact" component={Contact} />
        <Route path="/dark-contact" component={DarkContact} />

        <Route path="/faq" component={Faq} />
        <Route path="/dark-faq" component={DarkFaq} />

        <Route path="/404" component={ErrorPage} />
        <Route path="/dark-404" component={DarkErrorPage} />
        <Route component={ErrorPage} />
      </Switch>
    </Router>
  );
}
export default App;
