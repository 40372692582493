import React from "react";
import "../../assets/styles/Module/contact.scss";

const BlogComment = ({ DarkInputBg }) => {
  return (
    <div className="comments__form mb-95">
      <div className="container">
        <h3 className="contact__form--title mb-30">Leave a Reply</h3>
        <form className="contact__form--inner">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="contact__form--list mb-20">
                <label className="contact__form--label" for="input1">
                  First Name <span>*</span>
                </label>
                <input
                  className={`border contact__form--input ${DarkInputBg}`}
                  name="firstname"
                  id="input1"
                  placeholder="Your First Name"
                  type="text"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact__form--list mb-20">
                <label className="contact__form--label" for="input4">
                  Email <span>*</span>
                </label>
                <input
                  className={`border contact__form--input ${DarkInputBg}`}
                  name="email"
                  id="input4"
                  placeholder="Email"
                  type="text"
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="contact__form--list mb-20">
                <label className="contact__form--label" for="input2">
                  Website <span>*</span>
                </label>
                <input
                  className={`border contact__form--input ${DarkInputBg}`}
                  name="lastname"
                  id="input2"
                  placeholder="Website"
                  type="text"
                />
              </div>
            </div>
            <div className="col-12">
              <div className="contact__form--list mb-15">
                <label className="contact__form--label" for="input5">
                  Write Your Message <span>*</span>
                </label>
                <textarea
                  className={`border contact__form--textarea ${DarkInputBg}`}
                  name="message"
                  id="input5"
                  placeholder="Write Your Message"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="contact__form--btn">
            <button className="action__btn border-0" type="submit">
              {" "}
              <span>Submit Now</span>
            </button>
          </div>
          <p className="form-messege"></p>
        </form>
      </div>
    </div>
  );
};

export default BlogComment;
