import React from "react";
import { NavLink } from "react-router-dom";
import "./portfolio.scss";

const Portfolio = ({
  title,
  thumb,
  subtitle,
  description,
  id,
  DarkButtonTextColor,
}) => {
  return (
    <div className="portfolio__grid--items">
      <div className="portfolio__thumb">
        <NavLink
          className="portfolio__thumb--link"
          to={`/portfolio-details/${id}`}
        >
          <img className="portfolio__thumb--img" src={thumb} alt={title} />
        </NavLink>
        <div className="portfolio__icon">
          <button
            data-fancybox="gallery"
            data-src={thumb}
            className={`portfolio__icon--link ${DarkButtonTextColor}`}
          >
            <i className="icofont-plus"></i>
          </button>
        </div>
        <div className="portfolio__content">
          <NavLink
            className="portfolio__subtitle"
            to={`/portfolio-details/${id}`}
          >
            {subtitle}
          </NavLink>
          <h3 className="portfolio__maintitle">
            <NavLink
              className="portfolio__maintitle--link"
              to={`/portfolio-details/${id}`}
            >
              {title}
            </NavLink>
          </h3>
          <p className="portfolio__desc">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
