import React from "react";
import Team from "../../components/Team/Team";
import "../../assets/styles/Module/team.scss";
import TeamData from "../../data/Team/Team.json";

const TeamSection = () => {
  return (
    <section className="team__section mb-100">
      <div className="container">
        <div className="section__title text-center mb-40">
          <span className="section__subtitle gradient__text mb-12">
            {TeamData.subtitle}
          </span>
          <h2 className="section__title--text">{TeamData.title}</h2>
        </div>
        <div className="team__Wrapper">
          <div className="row">
            {TeamData &&
              TeamData.members.map((singleTeam) => {
                return (
                  <div
                    key={singleTeam.id}
                    className="col-lg-4 col-md-4 col-sm-6 col-6 custom-col"
                  >
                    <Team
                      image={singleTeam.image}
                      name={singleTeam.name}
                      designation={singleTeam.designation}
                      facebook={singleTeam.facebook}
                      twitter={singleTeam.twitter}
                      pinterest={singleTeam.pinterest}
                      instagram={singleTeam.instagram}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
