import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const SingleTestimonialItem = ({ data, SingleTestmonialDarkCardBg }) => {
  return (
    <div className="swiper-slide">
      <div className="testimonial__items d-flex">
        <div className={`testimonial__info ${SingleTestmonialDarkCardBg}`}>
          <div className="testimonial__thumb">
            <img src={data.image} alt={data.name} />
          </div>
          <div className="testimonial__author">
            <h3 className="testimonial__author--maintitle">{data.name}</h3>
            <h5 className="testimonial__author--subtitle">
              {data.designation}
            </h5>
          </div>
        </div>
        <div className={`testimonial__content ${SingleTestmonialDarkCardBg}`}>
          <div className="testimonial__content--top border-bottom">
            <h3 className="testimonial__content--title mb-12">{data.title}</h3>
            <span className="testimonial__dates">{data.date}</span>
            <i className="testimonial__quote--icon icofont-quote-left"></i>
          </div>
          <div className="testimonial__content--bottom">
            <p className="testimonial__desc">{data.description}</p>
            <div className="testimonial__rating">
              <ul className="d-flex">
                <li className="testimonial__rating--list">
                  <Link to="#" className="testimonial__rating--icon">
                    <i className="rating__start--icon icofont-star"></i>
                  </Link>
                </li>
                <li className="testimonial__rating--list">
                  <Link to="#" className="testimonial__rating--icon">
                    <i className="rating__start--icon icofont-star"></i>
                  </Link>
                </li>
                <li className="testimonial__rating--list">
                  <Link to="#" className="testimonial__rating--icon">
                    <i className="rating__start--icon icofont-star"></i>
                  </Link>
                </li>
                <li className="testimonial__rating--list">
                  <Link to="#" className="testimonial__rating--icon">
                    <i className="rating__start--icon icofont-star"></i>
                  </Link>
                </li>
                <li className="testimonial__rating--list">
                  <Link to="#" className="testimonial__rating--icon">
                    <i className="rating__start--icon icofont-star"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
SingleTestimonialItem.propTypes = {
  data: PropTypes.object,
};
export default SingleTestimonialItem;
