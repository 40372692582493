import React from "react";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Wrapper from "../../../components/UI/Wrapper";
import SinglePortfolio from "../../portfolio/SinglePortfolio";
import PortfolioGallery from "../../portfolio/PortfolioGallery";
import Video from "../../../components/Video/Video";
import Footer from "../../../components/Footer/Footer";
import DarkLayout from "../../../components/UI/DarkLayout";

function DarkPortfolioDetails(props) {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Dark Portfolio Details`} />
        <Breadcrumb
          title="Portfolio Details"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <SinglePortfolio {...props} />
        <PortfolioGallery DarkBgcolor="related__dark template__dark--bg2" />
        <Video />
        <Footer DarkSectionBg="template__dark--bg3" />
      </DarkLayout>
    </Wrapper>
  );
}

export default DarkPortfolioDetails;
