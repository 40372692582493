import React from "react";
import Slider, { SwiperSlide } from "../../components/UI/Slider";
import SingleTestmonialItem from "../../components/Testimonial/SingleTestmonialItem";
import "../../assets/styles/Module/testimonial.scss";

import TestimonialData from "../../data/Testimonial/Testimonial.json";

const Testimonial = ({ TestmonialDarkSectionBg, TestmonialDarkCardBg }) => {
  return (
    <section
      className={`testimonial__section ${TestmonialDarkSectionBg} mb-95`}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section__title text-center mb-25">
              <span className="section__subtitle gradient__text mb-12">
                {TestimonialData.subtitle}
              </span>
              <h2 className="section__title--text">{TestimonialData.title}</h2>
            </div>

            <div className="testimonial__activation">
              <Slider
                className={`testimonial__wrapper`}
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
              >
                {TestimonialData &&
                  TestimonialData.testimonialData.map((singleItem) => {
                    return (
                      <SwiperSlide key={singleItem.id}>
                        <SingleTestmonialItem
                          data={singleItem}
                          SingleTestmonialDarkCardBg={TestmonialDarkCardBg}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Testimonial;
