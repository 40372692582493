import React from "react";
import Header from "../../components/Header/Header";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import About from "../../components/About/About";
import ServicesSection from "../home/ServicesSection";
import MyResume from "../../components/Resume/MyResume";
import PortfolioSection from "../home/PortfolioSection";
import Testimonial from "../home/Testimonial";
import LatestBlog from "../home/LatestBlog";
import ContactSection from "../../components/Contact/ContactSection";
import Footer from "../../components/Footer/Footer";
import Wrapper from "../../components/UI/Wrapper";

const Home = () => {
  return (
    <Wrapper>
      <Header />
      <HeroBanner />
      <About />
      <ServicesSection />
      <MyResume />
      <Testimonial />
      <PortfolioSection />
      <LatestBlog />
      <ContactSection />
      <Footer />
    </Wrapper>
  );
};

export default Home;
