import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./about.scss";
import AboutData from "../../data/About/AboutMe.json";

const About = ({ DarkTextColor }) => {
  return (
    <Fragment>
      <section className="about__section mb-95">
        <div className="container">
          <div className="about__top--bar border-bottom mb-55 pb-60">
            <div className="row">
              <div className="col-lg-5">
                <div className="about__thumb">
                  <img
                    className="about__thumb--img"
                    src={AboutData.image}
                    alt={AboutData.title}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about__content">
                  <div className="section__title about__section--title mb-30">
                    <span className="section__subtitle gradient__text mb-12">
                      {AboutData.subtitle}
                    </span>
                    <h2 className="section__title--text mb-20">
                      {AboutData.title}
                    </h2>
                    <p className="section__title--desc">
                      {AboutData.descirption}
                    </p>
                  </div>
                  <div className="about__info">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 custom-col">
                        <div className="about__info--list">
                          <ul>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.name[0]}
                              </b>{" "}
                              {AboutData.info.name[1]}
                            </li>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.dob[0]}
                              </b>{" "}
                              {AboutData.info.dob[1]}
                            </li>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.age[0]}
                              </b>{" "}
                              {AboutData.info.age[1]}
                            </li>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.address[0]}
                              </b>{" "}
                              {AboutData.info.address[1]}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 custom-col">
                        <div className="about__info--list">
                          <ul>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.phone[0]}
                              </b>
                              <Link
                                to={`${AboutData.info.phone[1]}`}
                                className="about__info--link"
                              >
                                {AboutData.info.phone[1]}
                              </Link>
                            </li>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.email[0]}
                              </b>
                              <Link to="#" className="about__info--link">
                                {AboutData.info.email[1]}
                              </Link>
                            </li>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.skype[0]}
                              </b>{" "}
                              {AboutData.info.skype[1]}
                            </li>
                            <li
                              className={`about__info--list__item ${DarkTextColor}`}
                            >
                              <b className="about__info--ahthor">
                                {AboutData.info.work[0]}
                              </b>{" "}
                              {AboutData.info.work[1]}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about__skill">
            <div className="row about__skill--row">
              {AboutData &&
                AboutData.profession.map((singleItem) => {
                  return (
                    <div
                      key={singleItem.id}
                      className="col-lg-3 col-md-4 col-sm-6 col-6 custom-col about__skill--col"
                    >
                      <div className="about__skill--list">
                        <h4 className="about__skill--title mb-12">
                          {singleItem.title}
                        </h4>
                        <p className="about__skill--desc mb-12">
                          {singleItem.description}
                        </p>
                        <Link
                          className="about__skill--link gradient__text"
                          to="#"
                        >
                          {singleItem.button}
                          <i className="about__skill--link__icon icofont-rounded-double-right"></i>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
