import React from "react";
import { Link } from "react-router-dom";
import "../../assets/styles/Module/footer.scss";

const Footer = ({ DarkSectionBg }) => {
  return (
    <footer className={`footer__section ${DarkSectionBg}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer__inner d-flex align-items-center justify-content-between">
              <p className="copyright__content">
                Copyright © 2021. all right reserved
              </p>
              <div className="footer__social">
                <ul className="d-flex">
                  <li className="footer__social--list">
                    <Link className="footer__social--link" to="/#">
                      <i className="footer__social--icon icofont-facebook"></i>
                    </Link>
                  </li>
                  <li className="footer__social--list">
                    <Link className="footer__social--link" to="/#">
                      <i className="footer__social--icon icofont-twitter"></i>
                    </Link>
                  </li>
                  <li className="footer__social--list">
                    <Link className="footer__social--link" to="/#">
                      <i className="footer__social--icon icofont-linkedin"></i>
                    </Link>
                  </li>
                  <li className="footer__social--list">
                    <Link className="footer__social--link" to="/#">
                      <i className="footer__social--icon icofont-pinterest"></i>
                    </Link>
                  </li>
                  <li className="footer__social--list">
                    <Link className="footer__social--link" to="/#">
                      <i className="footer__social--icon icofont-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
