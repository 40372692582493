import React from "react";
import Wrapper from "../../../components/UI/Wrapper";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Footer from "../../../components/Footer/Footer";
import ContactSection from "../../../components/Contact/ContactSection";
import Map from "../../../components/Contact/Map";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkContact = () => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Contact Dark`} />
        <Breadcrumb
          title="Contact Us"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <ContactSection
          DarkCardBg="template__dark--bg3"
          DarkInputBg="border__color2 template__dark--bg2"
        />
        <Map />
        <Footer DarkSectionBg="template__dark--bg2" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkContact;
