import React from "react";

const ProgressBar = (props) => {
  let styels = {
    width: `${props.value}%`,
  };
  return (
    <div className="skill__progress">
      <h5 className="resume__skill--subtitle">{props.name}</h5>
      <div className="skill__progress--inner progress">
        <div className="skill__progressbar progress-bar" style={styels}>
          <span className="skill__progressbar--width percent-label">
            {props.value}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
