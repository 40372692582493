import React from "react";
import Wrapper from "../../../components/UI/Wrapper";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Footer from "../../../components/Footer/Footer";
import PortfolioSection from "../../home/PortfolioSection";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkPortfolio = () => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Portfolio Dark`} />
        <Breadcrumb
          title="Portfolio"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <PortfolioSection DarkButtonTextColor="text__primary" />
        <Footer DarkSectionBg="template__dark--bg2" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkPortfolio;
