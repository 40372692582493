import React, { Fragment } from "react";
import "./resume.scss";
import ProgressBar from "../UI/ProgressBar";
import ResumeData from "../../data/Resume/Resume.json";

const MyResume = ({ DarkCardBg }) => {
  return (
    <Fragment>
      <section className="resume__section mb-100">
        <div className="container">
          <div className="section__title text-center mb-50">
            <span className="section__subtitle gradient__text mb-12">
              {ResumeData.subtitle}
            </span>
            <h2 className="section__title--text">{ResumeData.title}</h2>
          </div>
          <div className="resume__container">
            <div className="resume__education--experience mb-50">
              <div className="row ">
                <div className="col-lg-6">
                  <div className="resume__step">
                    <div className="resume__header mb-40">
                      <h3 className="resume_maintitle gradient__border">
                        {ResumeData.education.title}
                      </h3>
                    </div>
                    <div className="resume__wrapper">
                      <div className="resume__timeline--vertical"></div>

                      {ResumeData &&
                        ResumeData.education.educationList.map((singleItem) => {
                          return (
                            <div
                              key={singleItem.id}
                              className={`resume__list ${DarkCardBg}`}
                            >
                              <div className="resume__timeline--bullet"></div>
                              <div className="resume__list--heading mb-15">
                                <h5 className="resume__list--subtitle">
                                  {singleItem.university}
                                  <span className="resume__dates">
                                    {singleItem.year}
                                  </span>
                                </h5>
                                <h4 className="resume__list--maintitle ">
                                  {singleItem.title}
                                </h4>
                              </div>
                              <div className="resume__desc">
                                <p className="resume__maindesc">
                                  {singleItem.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="resume__step">
                    <div className="resume__header mb-40">
                      <h3 className="resume_maintitle gradient__border">
                        {ResumeData.experience.title}
                      </h3>
                    </div>
                    <div className="resume__wrapper">
                      <div className="resume__timeline--vertical"></div>
                      {ResumeData &&
                        ResumeData.experience.experienceList.map(
                          (singleItem) => {
                            return (
                              <div
                                key={singleItem.id}
                                className={`resume__list ${DarkCardBg}`}
                              >
                                <div className="resume__timeline--bullet"></div>
                                <div className="resume__list--heading mb-15">
                                  <h5 className="resume__list--subtitle">
                                    {singleItem.university}
                                    <span className="resume__dates">
                                      {singleItem.year}
                                    </span>
                                  </h5>
                                  <h4 className="resume__list--maintitle">
                                    {singleItem.title}
                                  </h4>
                                </div>
                                <div className="resume__desc">
                                  <p className="resume__maindesc">
                                    {singleItem.description}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="resume__skill">
              <div className="resume__header mb-30">
                <h3 className="resume_maintitle gradient__border">My Skills</h3>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="resume__skill--step">
                    <div className="resume__skill--inner">
                      <ProgressBar name="PHOTOSHOT" value="100"></ProgressBar>
                      <ProgressBar name="FIGMA" value="80"></ProgressBar>
                      <ProgressBar name="ADOBE XD" value="60"></ProgressBar>
                      <ProgressBar
                        name=" ADOBE ILLUSTRATOR"
                        value="75"
                      ></ProgressBar>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="resume__skill--step">
                    <div className="resume__skill--inner">
                      <ProgressBar name="HTML" value="75"></ProgressBar>
                      <ProgressBar name="CSS" value="95"></ProgressBar>
                      <ProgressBar name="REACT" value="65"></ProgressBar>
                      <ProgressBar name="JAVASCRIPT" value="85"></ProgressBar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default MyResume;
