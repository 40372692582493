import React from "react";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import FaqSection from "../home/FaqSection";
import Wrapper from "../../components/UI/Wrapper";
import Footer from "../../components/Footer/Footer";

const Faq = () => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Faq`} />
      <Breadcrumb title="FAQ" />
      <FaqSection />
      <Footer />
    </Wrapper>
  );
};

export default Faq;
