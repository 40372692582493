import React from "react";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Wrapper from "../../components/UI/Wrapper";
import SinglePortfolio from "../portfolio/SinglePortfolio";
import PortfolioGallery from "../portfolio/PortfolioGallery";
import Video from "../../components/Video/Video";
import Footer from "../../components/Footer/Footer";

function PortfolioDetails(props) {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Portfolio Details`} />
      <Breadcrumb title="Portfolio Details" />
      <SinglePortfolio {...props} />
      <PortfolioGallery />
      <Video />
      <Footer />
    </Wrapper>
  );
}

export default PortfolioDetails;
