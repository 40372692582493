import React from "react";
import Slider, { SwiperSlide } from "../../components/UI/Slider";
import Article from "../../components/Blog/Article";
import BlogData from "../../data/Blog/Article.json";
import "../../assets/styles/Module/blog.scss";

const LatestBlog = ({ DarkSectionBg, DarkCardBg }) => {
  return (
    <section className={`blog__section ${DarkSectionBg} mb-95`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section__title text-center mb-40">
              <span className="section__subtitle gradient__text mb-12">
                Blog Your Feedback
              </span>
              <h2 className="section__title--text">My Blog</h2>
            </div>
            <div className="blog__activation ">
              <Slider
                className={`blog__Wrapper blog__container`}
                spaceBetween={30}
                slidesPerView={3}
                pagination={{ clickable: true }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  575: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
              >
                {BlogData &&
                  BlogData.map((singleItem) => {
                    return (
                      <SwiperSlide key={singleItem.id}>
                        <Article
                          article={singleItem}
                          DarkCardArticleBg={DarkCardBg}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestBlog;
