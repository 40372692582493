import React, { Fragment } from "react";
import Services from "../../components/Services/Services";
import "../../assets/styles/Module/services.scss";
import ServicesData from "../../data/Services/ServicesData.json";

const ServicesSection = ({ ServiceDarkCardBg }) => {
  return (
    <Fragment>
      <section className={`services__section services__bg--none mb-95 mb-95 `}>
        <div className="container">
          <div className="section__title text-center mb-40">
            <span className="section__subtitle gradient__text mb-12">
              {ServicesData.subtitle}
            </span>
            <h2 className="section__title--text">{ServicesData.title}</h2>
          </div>
          <div className="services__inner">
            <div className="row">
              {ServicesData.servicesAllData.map((singleServie) => {
                return (
                  <div
                    key={singleServie.id}
                    className="col-lg-4 col-md-6 col-sm-6 col-6 custom-col"
                  >
                    <Services
                      data={singleServie}
                      ServiceDarkCardBg={ServiceDarkCardBg}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default ServicesSection;
