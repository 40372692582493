import React from "react";
import Card from "../UI/Card";
import ContactForm from "./ContactForm";
import "../../assets/styles/Module/contact.scss";
import ContactData from "../../data/Contact/Contact.json";

const ContactSection = ({ DarkCardBg, DarkInputBg }) => {
  return (
    <section className="contact__section mb-100">
      <div className="container">
        <div className="section__title text-center mb-50">
          <span className="section__subtitle gradient__text mb-12">
            {ContactData.subtitle}
          </span>
          <h2 className="section__title--text">{ContactData.title}</h2>
        </div>

        <div className="contact__information mb-60">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 custom-col">
              <Card className={`contact__info--items ${DarkCardBg}`}>
                <i className={`contact__info--icon icofont-ui-touch-phone`}></i>
                <h4 className="contact__info--title mb-15">
                  {ContactData.phone.title}
                </h4>
                <p className="contact__info--text">
                  <a
                    className="contact__info--link"
                    href={`tel:${ContactData.phone.num1}`}
                  >
                    {ContactData.phone.num1}
                  </a>
                  <br />
                  <a
                    className="contact__info--link"
                    href={`tel:${ContactData.phone.num2}`}
                  >
                    {ContactData.phone.num2}
                  </a>
                </p>
              </Card>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 custom-col">
              <Card className={`contact__info--items ${DarkCardBg}`}>
                <i className={`contact__info--icon icofont-envelope`}></i>
                <h4 className="contact__info--title mb-15">
                  {ContactData.email.title}
                </h4>
                <p className="contact__info--text">
                  <a
                    className="contact__info--link"
                    href={`mailto:${ContactData.email.email1}`}
                  >
                    {ContactData.email.email1}
                  </a>
                  <br />
                  <a
                    className="contact__info--link"
                    href={`mailto:${ContactData.email.email2}`}
                  >
                    {ContactData.email.email2}
                  </a>
                </p>
              </Card>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-6 custom-col">
              <Card className={`contact__info--items ${DarkCardBg}`}>
                <i className={`contact__info--icon icofont-location-pin`}></i>
                <h4 className="contact__info--title mb-15">
                  {ContactData.address.title}
                </h4>
                <p className="contact__info--text">
                  {ContactData.address.location}
                </p>
              </Card>
            </div>
          </div>
        </div>

        <div className="main__contact--area">
          <div className="row">
            <div className="col-lg-5 col-md-8 offset-md-2 offset-lg-0">
              <div className="contact__about">
                <div className="contact__thumbnail">
                  <img
                    className="contact__thumbnail--img"
                    src={ContactData.author.image}
                    alt={ContactData.author.title}
                  />
                </div>
                <div className={`contact__about--content ${DarkCardBg}`}>
                  <h3 className="contact__about--title mb-12">
                    {ContactData.author.title}
                  </h3>
                  <h5 className="contact__about--subtitle mb-20">
                    {ContactData.author.subtitle}
                  </h5>
                  <p className="contact__about--desc">
                    {ContactData.author.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className={`contact__form ${DarkCardBg}`}>
                <h3 className="contact__form--title mb-30">Contact Me</h3>
                <ContactForm
                  DarkCardBg={DarkCardBg}
                  DarkInputBg={DarkInputBg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
