import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";
SwiperCore.use([Pagination]);

const Slider = ({ children, ...props }) => {
  const settings = {
    ...props,
  };
  return <Swiper {...settings}>{children}</Swiper>;
};
export { SwiperSlide };
export default Slider;
