import React from "react";
import { Link } from "react-router-dom";

const Team = ({
  image,
  name,
  designation,
  facebook,
  twitter,
  pinterest,
  instagram,
}) => {
  return (
    <div className="team__items">
      <div className="team__thumb">
        <img className="team__thumb--img" src={image} alt={name} />
        <div className="team__content">
          <span className="team__content--subtitle">{designation}</span>
          <h4 className="team__content--title mb-20">{name}</h4>
          <ul className="team__social d-flex justify-content-center">
            <li className="team__social--list">
              <Link
                to={{ pathname: `${facebook}` }}
                target="_blank"
                className="team__social--link"
              >
                <i className="team__social--icon icofont-facebook"></i>
              </Link>
            </li>
            <li className="team__social--list">
              <Link
                to={{ pathname: `${twitter}` }}
                target="_blank"
                className="team__social--link"
              >
                <i className="team__social--icon icofont-twitter"></i>
              </Link>
            </li>
            <li className="team__social--list">
              <Link
                to={{ pathname: `${pinterest}` }}
                target="_blank"
                className="team__social--link"
              >
                <i className="team__social--icon icofont-pinterest"></i>
              </Link>
            </li>
            <li className="team__social--list">
              <Link
                to={{ pathname: `${instagram}` }}
                target="_blank"
                className="team__social--link"
              >
                <i className="team__social--icon icofont-instagram"></i>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Team;
