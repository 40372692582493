import React, { Fragment } from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import "./heroBanner.scss";
import Button from "../UI/Button";
import IconSocial from "../UI/IconSocial";
import HeroBannerData from "../../data/HeroBanner/HeroBanner.json";

function HeroBanner() {
  return (
    <Fragment>
      <section
        className="hero__section mb-100"
        style={{ backgroundImage: `url(${HeroBannerData.image})` }}
      >
        <div className="container">
          <div className="hero__container">
            <div className="row align-items-center">
              <div className="col-lg-10 col-md-10">
                <div className="hero__content">
                  <h4 className="hero__content--sub__title gradient__text mb-12">
                    {HeroBannerData.subtitle}
                  </h4>
                  <h1 className="hero__content--title cd-headline clip is-full-width mb-25">
                    <span className="hero__content--title__padding cd-words-wrapper">
                      <b className="is-visible">
                        {HeroBannerData.title.fist}
                        <span className="hero__content--title__color">
                          <Typed
                            strings={HeroBannerData.title.second}
                            typeSpeed={100}
                            backSpeed={50}
                            loop
                          />
                        </span>
                      </b>
                    </span>
                  </h1>

                  <p className="hero__content--desc mb-30">
                    {HeroBannerData.description}
                  </p>
                  <Button className="hero__content--link">
                    {HeroBannerData.button}
                    <i className="action__btn--icon icofont-rounded-double-right"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero__contact--info">
          <p className="hero__contact--info__text">
            <Link
              to={`tel: ${HeroBannerData.phone}`}
              className="hero__contact--info__number"
            >
              {HeroBannerData.phone}
            </Link>
          </p>
          <p className="hero__contact--info__text">{HeroBannerData.text}</p>
        </div>
        <div className="social__share">
          <ul className="social__share--inner">
            <li className="social__share--list">
              <Link to="#">
                <IconSocial>
                  <i className="social__share--icon icofont-facebook"></i>
                </IconSocial>
              </Link>
            </li>
            <li className="social__share--list">
              <Link to="#">
                <IconSocial>
                  <i className="social__share--icon icofont-twitter"></i>
                </IconSocial>
              </Link>
            </li>
            <li className="social__share--list">
              <Link to="#">
                <IconSocial>
                  <i className="social__share--icon icofont-linkedin"></i>
                </IconSocial>
              </Link>
            </li>
            <li className="social__share--list">
              <Link to="#">
                <IconSocial>
                  <i className="social__share--icon icofont-pinterest"></i>
                </IconSocial>
              </Link>
            </li>
            <li className="social__share--list">
              <Link to="#">
                <IconSocial>
                  <i className="social__share--icon icofont-instagram"></i>
                </IconSocial>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
}

export default HeroBanner;
