import React from "react";
import { NavLink } from "react-router-dom";

const MobileNav = () => {
  return (
    <ul className="offcanvas__menu_ul">
      <li className="offcanvas__menu_li">
        <NavLink className="offcanvas__menu_item" to="/">
          Main Home
        </NavLink>
        <ul className="offcanvas__sub_menu">
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/">
              Main Home
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/home-dark">
              Main Home Dark
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="offcanvas__menu_li">
        <NavLink className="offcanvas__menu_item" to="/about-us">
          About
        </NavLink>
        <ul className="offcanvas__sub_menu">
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/about-us">
              About Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/dark-about">
              About Dark
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="offcanvas__menu_li">
        <NavLink className="offcanvas__menu_item" to="/portfolio">
          Services
        </NavLink>
        <ul className="offcanvas__sub_menu">
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/service">
              Services Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/dark-service">
              Services Dark
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink
              className="offcanvas__sub_menu_item"
              to="/service-details/1"
            >
              Single Services Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink
              className="offcanvas__sub_menu_item"
              to="/dark-service-details/1"
            >
              Single Services Dark
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="offcanvas__menu_li">
        <NavLink className="offcanvas__menu_item" to="/portfolio">
          Portfolio
        </NavLink>
        <ul className="offcanvas__sub_menu">
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/service">
              Portfolio Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/dark-portfolio">
              Portfolio Dark
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink
              className="offcanvas__sub_menu_item"
              to="/portfolio-details/1"
            >
              Single Portfolio Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink
              className="offcanvas__sub_menu_item"
              to="/dark-portfolio-details/1"
            >
              Single Services Dark
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="offcanvas__menu_li">
        <NavLink className="offcanvas__menu_item" to="/blog">
          Blog
        </NavLink>
        <ul className="offcanvas__sub_menu">
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/blog">
              Blog Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/dark-blog">
              Blog Dark
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/blog-post/1">
              Single Blog Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink
              className="offcanvas__sub_menu_item"
              to="/dark-blog-post/1"
            >
              Single Blog Dark
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="offcanvas__menu_li">
        <NavLink className="offcanvas__menu_item" to="/contact">
          Contact
        </NavLink>
        <ul className="offcanvas__sub_menu">
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/contact">
              Contact Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/dark-contact">
              Contact Dark
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="offcanvas__menu_li">
        <NavLink className="offcanvas__menu_item" to="/#">
          Pages
        </NavLink>
        <ul className="offcanvas__sub_menu">
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/faq">
              FAQ Light
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/dark-faq">
              FAQ Dark
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/404">
              404
            </NavLink>
          </li>
          <li className="offcanvas__sub_menu_li">
            <NavLink className="offcanvas__sub_menu_item" to="/dark-404">
              404 Dark
            </NavLink>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default MobileNav;
