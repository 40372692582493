import React from "react";

const Button = (props) => {
  return (
    <button
      className={`action__btn ${props.className}`}
      type={props.type || "button"}
    >
      {props.children}
    </button>
  );
};

export default Button;
