import React from "react";
import Wrapper from "../../../components/UI/Wrapper";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Footer from "../../../components/Footer/Footer";
import ServicesSection from "../../services/ServicesSection";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkServicesPage = () => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Services Dark`} />
        <Breadcrumb
          title="Services"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <ServicesSection ServiceDarkCardBg="template__dark--bg2" />
        <Footer DarkSectionBg="template__dark--bg2" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkServicesPage;
