import React from "react";
import Header from "../../../components/Header/Header";
import HeroBannerDark from "../../../components/HeroBanner/HeroBannerDark";
import About from "../../../components/About/About";
import ServicesSection from "../../home/ServicesSection";
import MyResume from "../../../components/Resume/MyResume";
import PortfolioSection from "../../home/PortfolioSection";
import Testimonial from "../../home/Testimonial";
import LatestBlog from "../../home/LatestBlog";
import ContactSection from "../../../components/Contact/ContactSection";
import Footer from "../../../components/Footer/Footer";
import Wrapper from "../../../components/UI/Wrapper";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkHome = () => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Home Dark`} />
        <HeroBannerDark />
        <About DarkTextColor="text__color2" />
        <ServicesSection
          DarkBgColor="template__dark--bg2"
          ServiceDarkCardBg="template__dark--bg3"
        />
        <MyResume DarkCardBg="template__dark--bg3" />
        <Testimonial
          TestmonialDarkSectionBg="template__dark--bg2"
          TestmonialDarkCardBg="template__dark--bg3"
        />
        <PortfolioSection DarkButtonTextColor="text__primary" />
        <LatestBlog
          DarkSectionBg="template__dark--bg2"
          DarkCardBg="template__dark--bg3"
        />
        <ContactSection
          DarkCardBg="template__dark--bg3"
          DarkInputBg="border__color2 template__dark--bg2"
        />
        <Footer DarkSectionBg="template__dark--bg2" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkHome;
