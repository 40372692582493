import React, { Fragment, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MobileNav from "./MobileNav";
import MainNav from "./MainNav";
import "../../assets/css/vendor/bootstrap.min.css";
import "../../assets/css/vendor/icofont.min.css";
import "../../assets/styles/global.scss";
import "../../assets/styles/Module/header.scss";
import "../../assets/styles/custom.scss";
import "../../assets/styles/dark_Module.scss";

import Logo from "../../assets/img/logo/logo.png";
import Logodark from "../../assets/img/logo/logo2.png";

import { Helmet } from "react-helmet";

const Header = ({ pageTitle }) => {
  const [mobileNavAddClass, setMobileNavAddClass] = useState("");
  const [stickyClass, setstickyClass] = useState("");

  const mobileMenuOnClick = () => {
    setMobileNavAddClass("active");
    //document.body.classList.add("open_sidebar");
  };
  const mobileMenuOnClose = () => {
    setMobileNavAddClass("");
    //document.body.classList.remove("open_sidebar");
  };

  useEffect(() => {
    /* Get Sibling */
    var getSiblings = function (elem) {
      const siblings = [];
      let sibling = elem.parentNode.firstChild;
      while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
          siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
      }
      return siblings;
    };
    const offcanvasMenu = document.querySelector(".offcanvas__menu");
    /* Add a Button For SubMenu Toggle */
    offcanvasMenu
      .querySelectorAll(".offcanvas__sub_menu")
      .forEach(function (ul) {
        const subMenuToggle = document.createElement("button");
        subMenuToggle.classList.add("offcanvas__sub_menu_toggle");
        ul.parentNode.appendChild(subMenuToggle);
      });

    /* Open/Close Sub Menu On Click Toggle Button */
    offcanvasMenu
      .querySelectorAll(".offcanvas__sub_menu_toggle")
      .forEach(function (toggle) {
        toggle.addEventListener("click", function (e) {
          e.preventDefault();
          const parent = this.parentElement;
          if (parent.classList.contains("active")) {
            this.classList.remove("active");
            parent.classList.remove("active");
            parent
              .querySelectorAll(".offcanvas__sub_menu")
              .forEach(function (subMenu) {
                subMenu.parentElement.classList.remove("active");
                subMenu.nextElementSibling.classList.remove("active");
                //slideUp(subMenu);
              });
          } else {
            this.classList.add("active");
            parent.classList.add("active");
            //slideDown(this.previousElementSibling);
            getSiblings(parent).forEach(function (item) {
              item.classList.remove("active");
              item
                .querySelectorAll(".offcanvas__sub_menu")
                .forEach(function (subMenu) {
                  subMenu.parentElement.classList.remove("active");
                  subMenu.nextElementSibling.classList.remove("active");
                  //slideUp(subMenu);
                });
            });
          }
        });
      });

    // Sticky JS
    window.addEventListener("scroll", function () {
      const header = document.querySelector(".header--sticky");
      const scroll = window.pageYOffset | header.scrollTop;

      if (scroll > 100) {
        setstickyClass("sticky");
      } else if (scroll <= 100) {
        setstickyClass("");
      }
    });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div
        className={`body__overlay ${mobileNavAddClass}`}
        onClick={() => mobileMenuOnClose()}
      ></div>
      <div className={`offcanvs__moile_menu ${mobileNavAddClass}`}>
        <div className="offcanvas__inner">
          <div className="offcanvas__menu_header">
            <button
              className="offcanvas__close_btn"
              onClick={() => mobileMenuOnClose()}
            >
              <i className="icofont-close"></i> close
            </button>
          </div>
          <nav className="offcanvas__menu">
            <MobileNav />
          </nav>
        </div>
      </div>
      <header
        className={`header__section header__transparent header--sticky ${stickyClass}`}
      >
        <div className="container-fluid width-calc">
          <div className="row">
            <div className="col-12">
              <div className="header__section--inner d-flex justify-content-between align-items-center">
                <div className="main__logo">
                  <NavLink to="/" className="main__logo--link">
                    <img className="main__logo--img" src={Logo} alt="" />
                    <img className="main__logo--img_dark" src={Logodark} alt="" />
                  </NavLink>
                </div>
                <div className="nav__menu d-none d-lg-block">
                  <MainNav />
                </div>
                <div className="mobile__menu--open">
                  <button
                    className="mobile__menu--open__icon"
                    onClick={() => mobileMenuOnClick()}
                  >
                    <i className="icofont-navigation-menu"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
