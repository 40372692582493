import React from "react";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Wrapper from "../../components/UI/Wrapper";
import SingleBlog from "../blog/SingleBlog";
import BlogComment from "../blog/BlogComment";
import Footer from "../../components/Footer/Footer";

const BlogDetails = (props) => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Blog Details`} />
      <Breadcrumb title="Blog Details" />
      <SingleBlog {...props} />
      <BlogComment />
      <Footer />
    </Wrapper>
  );
};

export default BlogDetails;
