import React from "react";
import Wrapper from "../../components/UI/Wrapper";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";
import ServicesSection from "../services/ServicesSection";
const ServicesPage = () => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Service`} />
      <Breadcrumb title="Services" />
      <ServicesSection />
      <Footer />
    </Wrapper>
  );
};

export default ServicesPage;
