import React from "react";
import { NavLink } from "react-router-dom";
import Button from "../UI/Button";

function MainNav() {
  return (
    <nav>
      <ul className="d-flex align-items-center">
        <li className="nav__menu--items">
          <NavLink className="nav__menu--items__link" to="/">
            Home
          </NavLink>
          <ul className="header__sub--menu">
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/">
                Main Home
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/home-dark">
                Main Home Dark
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav__menu--items">
          <NavLink className="nav__menu--items__link" to="/about-us">
            About us
          </NavLink>
          <ul className="header__sub--menu">
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/about-us">
                About Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/dark-about">
                About Dark
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav__menu--items">
          <NavLink className="nav__menu--items__link" to="/service">
            Services
          </NavLink>
          <ul className="header__sub--menu">
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/service">
                Services Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/dark-service">
                Services Dark
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink
                className="header__sub--menu__link"
                to={`/service-details/1`}
              >
                Single Services Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink
                className="header__sub--menu__link"
                to={`/dark-service-details/1`}
              >
                Single Services Dark
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav__menu--items">
          <NavLink className="nav__menu--items__link" to="/portfolio">
            portfolio
          </NavLink>
          <ul className="header__sub--menu">
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/portfolio">
                Portfolio Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/dark-portfolio">
                Portfolio Dark
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink
                className="header__sub--menu__link"
                to="/portfolio-details/1"
              >
                Single Portfolio Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink
                className="header__sub--menu__link"
                to="/dark-portfolio-details/1"
              >
                Single Portfolio Dark
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav__menu--items">
          <NavLink className="nav__menu--items__link" to="/blog">
            Blog
          </NavLink>
          <ul className="header__sub--menu">
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/blog">
                Blog Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/dark-blog">
                Blog Dark
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/blog-post/1">
                Single Blog Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink
                className="header__sub--menu__link"
                to="/dark-blog-post/1"
              >
                Single Blog Dark
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav__menu--items">
          <NavLink className="nav__menu--items__link" to="/contact">
            Contact
          </NavLink>
          <ul className="header__sub--menu">
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/contact">
                Contact Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/dark-contact">
                Contact Dark
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav__menu--items">
          <NavLink className="nav__menu--items__link" to="/">
            Pages
          </NavLink>
          <ul className="header__sub--menu">
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/faq">
                FAQ Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/dark-faq">
                FAQ Dark
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/404">
                404 Light
              </NavLink>
            </li>
            <li className="header__sub--menu__items">
              <NavLink className="header__sub--menu__link" to="/dark-404">
                404 Dark
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="nav__menu--items">
          <Button>
            BUY NOW
            <i className="menu__link--arrow__right icofont-rounded-double-right"></i>
          </Button>
        </li>
      </ul>
    </nav>
  );
}

export default MainNav;
