import React from "react";
import galleryImg1 from "../../assets/img/other/related1.png";
import galleryImg2 from "../../assets/img/other/related2.png";

const PortfolioGallery = ({ DarkBgcolor }) => {
  return (
    <section className={`related__post--section mb-95 ${DarkBgcolor}`}>
      <div className="container">
        <div className="related__post--Wrapper">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="related__post--items">
                <div className="related__post--thumb mb-20">
                  <img
                    className="related__post--img"
                    src={galleryImg1}
                    alt=""
                  />
                </div>
                <div className="related__post--text">
                  <h4 className="related__post--title mb-10">
                    <span className="related__post--title__link">
                      Post with Gallery
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              <div className="related__post--items">
                <div className="related__post--thumb mb-20">
                  <img
                    className="related__post--img"
                    src={galleryImg2}
                    alt=""
                  />
                </div>
                <div className="related__post--text">
                  <h4 className="related__post--title mb-10">
                    <span className="related__post--title__link">
                      Post with Gallery
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PortfolioGallery;
