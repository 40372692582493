import React from "react";
import Wrapper from "../../../components/UI/Wrapper";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Footer from "../../../components/Footer/Footer";
import About from "../../../components/About/About";
import ServicesSection from "../../home/ServicesSection";
import Testimonial from "../../home/Testimonial";
import TeamSection from "../../home/TeamSection";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkAboutUs = () => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekara | Home About`} />
        <Breadcrumb
          title="About US"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <About DarkTextColor="text__color2" />
        <ServicesSection
          DarkBgColor="template__dark--bg2"
          ServiceDarkCardBg="template__dark--bg3"
        />
        <TeamSection />
        <Testimonial
          TestmonialDarkSectionBg="template__dark--bg2 mb-0-important"
          TestmonialDarkCardBg="template__dark--bg3"
        />
        <Footer DarkSectionBg="template__dark--bg3" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkAboutUs;
