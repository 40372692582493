import React from "react";
import Wrapper from "../../components/UI/Wrapper";
import Header from "../../components/Header/Header";
import "../../assets/styles/Module/error.scss";
import Footer from "../../components/Footer/Footer";

const ErrorPage = () => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | 404`} />
      <section className="error__section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="error__content text-center">
                <h1 className="error__content--maintitle gradient__text">
                  Error 404
                </h1>
                <h3 className="error__content--subtitle mb-20">
                  Page Not Be Found
                </h3>
                <p className="error__content--desc">
                  Sorry but the page you are looking for does not exist, have
                  been
                </p>
                <a href="index.html" className=" action__btn">
                  BACK TO HOMEPAGE
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Wrapper>
  );
};

export default ErrorPage;
