import React from "react";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Wrapper from "../../../components/UI/Wrapper";
import SingleBlog from "../../blog/SingleBlog";
import BlogComment from "../../blog/BlogComment";
import Footer from "../../../components/Footer/Footer";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkBlogDetails = (props) => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Dark Blog Details`} />
        <Breadcrumb
          title="Blog Details"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <SingleBlog {...props} />
        <BlogComment DarkInputBg="border__color2 template__dark--bg2" />
        <Footer DarkSectionBg="template__dark--bg3" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkBlogDetails;
