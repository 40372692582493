import React from "react";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Wrapper from "../../components/UI/Wrapper";
import SingleService from "../services/SingleService";
import Video from "../../components/Video/Video";
import Footer from "../../components/Footer/Footer";

const ServicesDetails = (props) => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Service Details`} />
      <Breadcrumb title="Service Details" />
      <SingleService {...props} />
      <Video />
      <Footer />
    </Wrapper>
  );
};

export default ServicesDetails;
