import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

const Services = ({ data, ServiceDarkCardBg }) => {
  return (
    <div className={`services__box ${ServiceDarkCardBg}`}>
      <div className="services__box--inner">
        <i className={`services__icon ${data.icon}`}></i>
        <h3 className="services__box--title mb-18">{data.name}</h3>
        <p className="services__box--desc">{data.description}</p>
        <NavLink
          className="services__box--link"
          to={`/service-details/${data.id}`}
        >
          {data.button}
          <i className="services__box--link__icon icofont-rounded-double-right"></i>
        </NavLink>
      </div>
    </div>
  );
};
Services.propTypes = {
  data: PropTypes.object,
};
export default Services;
