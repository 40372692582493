import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import "../../assets/styles/Module/blog-details.scss";
import BlogData from "../../data/Blog/Article.json";

const SingleBlog = (props) => {
  const validId = parseInt(props.match.params.id);
  if (!validId) {
    return <Redirect to="/404" />;
  }

  const BlogDataObj = {};
  BlogData.forEach((post) => {
    if (validId === post.id) {
      BlogDataObj.title = post.title ? post.title : "No title given";
      BlogDataObj.image = post.image_full ? post.image_full : "No Image given";
      BlogDataObj.day = post.day ? post.day : "No day given";
      BlogDataObj.month = post.month ? post.month : "No month given";
      BlogDataObj.year = post.year ? post.year : "No year given";
      BlogDataObj.author = post.author ? post.author : "No author given";
      BlogDataObj.category = post.category
        ? post.category
        : "No category given";
      BlogDataObj.content = post.content ? post.content : "No content given";
    }
  });

  return (
    <section className="blog__details--section mb-95">
      <div className="container">
        <div className="blog__post--header mb-30">
          <h2 className="post__header--title mb-15">{BlogDataObj.title}</h2>
          <p className="blog__post--meta">
            Posted by:{" "}
            <span className="blog__post--meta__link">{BlogDataObj.author}</span>
            / On : {BlogDataObj.month} {BlogDataObj.day}, {BlogDataObj.year} /
            {BlogDataObj.category}
          </p>
        </div>
        <div className="blog__details--thumbnail mb-40">
          <img
            className="blog__details--thumbnail__img"
            src={BlogDataObj.image}
            alt={BlogDataObj.title}
          />
        </div>
        <div className="blog__details--content">
          <div
            className="blog__details--content__step mb-40"
            dangerouslySetInnerHTML={{ __html: BlogDataObj.content }}
          ></div>
          <div className="blog__post--tag">
            <ul className="post__tag--wrapper d-flex">
              {BlogData &&
                BlogData.map((singleItem) => {
                  return (
                    <li key={singleItem.id} className="post__tag--list">
                      <NavLink
                        className="post__tag--link"
                        to={`/blog-post/${singleItem.id}`}
                      >
                        {singleItem.category}
                      </NavLink>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleBlog;
