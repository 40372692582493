import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import Wrapper from "../../components/UI/Wrapper";
import "../../assets/styles/Module/single-services.scss";
import ServicesData from "../../data/Services/ServicesData.json";

const SingleService = (props) => {
  const validId = parseInt(props.match.params.id);
  if (!validId) {
    return <Redirect to="/404" />;
  }

  const singleServicesData = ServicesData.servicesAllData;
  console.log(singleServicesData);

  const ServiceDataObj = {};

  singleServicesData.forEach((post) => {
    if (validId === post.id) {
      ServiceDataObj.title = post.name ? post.name : "No title given";
      ServiceDataObj.image = post.content.image
        ? post.content.image
        : "No Image given";
      ServiceDataObj.description = post.content.description
        ? post.content.description
        : "No description given";
      ServiceDataObj.button = post.content.button
        ? post.content.button
        : "Text";
      ServiceDataObj.url = post.content.url ? post.content.url : "#";
      ServiceDataObj.fullDescription = post.fulldescription
        ? post.fulldescription
        : "#";
    }
  });

  return (
    <Wrapper>
      <section className="single__services--section mb-95">
        <div className="container">
          <div className="single__services--thumbnail mb-30">
            <img
              className="single__services--thumbnail__img"
              src={ServiceDataObj.image}
              alt=""
            />
          </div>
          <div className="single__services--content">
            <h2 className="single__services--content__title mb-20">
              {ServiceDataObj.title}
            </h2>
            <p className="single__services--content__desc d-none d-md-block">
              {ServiceDataObj.description}
            </p>
            <NavLink
              to={ServiceDataObj.url}
              className="action__btn single__services--content__btn"
            >
              {ServiceDataObj.button}
              <i className="action__btn--icon icofont-rounded-double-right"></i>
            </NavLink>
          </div>
        </div>
      </section>

      <section className="services__info--section mb-100">
        <div className="container">
          <div
            className="services__info--step"
            dangerouslySetInnerHTML={{ __html: ServiceDataObj.fullDescription }}
          ></div>
        </div>
      </section>
    </Wrapper>
  );
};

export default SingleService;
