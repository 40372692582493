import React from "react";
import { NavLink } from "react-router-dom";
import "./breadcrumb.scss";

const Breadcrumb = ({ title, DarkBgcolor, DarkTextColor }) => {
  return (
    <div className={`breadcrumb__area mb-100 ${DarkBgcolor}`}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb__text text-center">
              <h1 className="breadcrumb__title mb-20">{title}</h1>
              <nav>
                <ul className="d-flex justify-content-center">
                  <li className="breadcrumb__item">
                    <NavLink
                      className={`breadcrumb__link ${DarkTextColor}`}
                      to="/"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="breadcrumb__item active" aria-current="page">
                    {title}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
