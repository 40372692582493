import React from "react";
import Article from "../../components/Blog/Article";
import BlogData from "../../data/Blog/Article.json";
import "../../assets/styles/Module/blog.scss";

const Blog = ({ DarkCardBg }) => {
  return (
    <section className="blog__page--section mb-70">
      <div className="container">
        <div className="section__title text-center mb-40">
          <span className="section__subtitle gradient__text mb-12">
            Blog Your Feedback
          </span>
          <h2 className="section__title--text">My Blog</h2>
        </div>
        <div className="blog__page--Wrapper">
          <div className="row">
            {BlogData &&
              BlogData.map((singleItem) => {
                return (
                  <div
                    key={singleItem.id}
                    className="col-lg-4 col-md-6 col-sm-6 col-6 custom-col"
                  >
                    <Article
                      article={singleItem}
                      className={"blog__page--items"}
                      DarkCardBg={DarkCardBg}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
