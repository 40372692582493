import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import VideoImage from "../../assets/img/other/services-video-thumb.png";
import "./Video.css";
import "./modal-video.min.css";
import Wrapper from "../../components/UI/Wrapper";

const Video = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Wrapper>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="K7wCgW0qnpo"
        onClose={() => setOpen(false)}
      />
      <div className="services__video--section mb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="services__video--thumb">
                <img
                  className="services__video--img"
                  src={VideoImage}
                  alt={`video Popup`}
                />
                <div className="video__play--icon">
                  <button
                    className="video__play--link popup__vidio"
                    onClick={() => setOpen(true)}
                  >
                    <i className="video__play--link__icon icofont-ui-play"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Video;
