import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const Article = ({ article, className, DarkCardArticleBg, DarkCardBg }) => {
  return (
    <div className={`blog__items ${className}`}>
      <div className="blog__thumb">
        <NavLink className="blog__thumb--link" to={`/blog-post/${article.id}`}>
          <img
            className="blog__thumb--img"
            src={article.image}
            alt={article.title}
          />
        </NavLink>
        <div className="blog__date--meta">
          <p className="blog__date--inner">
            <span className="blog__date--days">{article.day}</span>
            <span className="blog__month">{article.month}</span>
          </p>
        </div>
      </div>
      <div className={`blog__contrent ${DarkCardArticleBg} ${DarkCardBg}`}>
        <span className="blog__contrent--tag">{article.category}</span>
        <h4 className="blog__manititle">
          <NavLink
            className="blog__manititle--link"
            to={`/blog-post/${article.id}`}
          >
            {article.title}
          </NavLink>
        </h4>
        <p className="blog__desc">{article.excerpt}</p>
        <NavLink className="blog__readmore" to={`/blog-post/${article.id}`}>
          Read More
          <i className="blog__link--icon icofont-rounded-double-right"></i>
        </NavLink>
      </div>
    </div>
  );
};
Article.propTypes = {
  article: PropTypes.object,
};

export default Article;
