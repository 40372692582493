import React from "react";
import { useForm } from "react-hook-form";

const ContactForm = ({ DarkInputBg }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => console.log(data);

  return (
    <form className="contact__form--inner" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="contact__form--list mb-20">
            <label className="contact__form--label" htmlFor="input1">
              First Name <span>*</span>
            </label>
            <input
              placeholder="Your First Name"
              type="text"
              id="input1"
              className={`border contact__form--input ${DarkInputBg}`}
              {...register("firstName", { required: true })}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="contact__form--list mb-20">
            <label className="contact__form--label" htmlFor="input2">
              Last Name <span>*</span>
            </label>
            <input
              placeholder="Your Last Name"
              type="text"
              id="input2"
              className={`border contact__form--input ${DarkInputBg}`}
              {...register("lastName")}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="contact__form--list mb-20">
            <label className="contact__form--label" htmlFor="input3">
              Phone Number <span>*</span>
            </label>
            <input
              placeholder="Phone number"
              type="tel"
              id="input3"
              className={`border contact__form--input ${DarkInputBg}`}
              {...register("phone", {
                required: true,
                minLength: 6,
                maxLength: 12,
              })}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="contact__form--list mb-20">
            <label className="contact__form--label" htmlFor="input4">
              Email <span>*</span>
            </label>
            <input
              placeholder="Email"
              type="email"
              id="input4"
              className={`border contact__form--input ${DarkInputBg}`}
              {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="contact__form--list mb-15">
            <label className="contact__form--label" htmlFor="input5">
              Write Your Message <span>*</span>
            </label>
            <textarea
              className={`border contact__form--textarea ${DarkInputBg}`}
              name="message"
              id="input5"
              placeholder="Write Your Message"
              {...register("message", { required: true })}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="contact__form--btn">
        <button className="action__btn border-0" type="submit">
          <span>Submit Now</span>
        </button>
      </div>
      {errors.firstName && (
        <p className="form-messege">First name is required.</p>
      )}
    </form>
  );
};

export default ContactForm;
