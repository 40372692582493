import React from "react";
import Wrapper from "../../components/UI/Wrapper";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";
import PortfolioSection from "../home/PortfolioSection";

const Portfolio = () => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | Portfolio`} />
      <Breadcrumb title="Portfolio" />
      <PortfolioSection />
      <Footer />
    </Wrapper>
  );
};

export default Portfolio;
