import React from "react";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Wrapper from "../../../components/UI/Wrapper";
import SingleService from "../../services/SingleService";
import Video from "../../../components/Video/Video";
import Footer from "../../../components/Footer/Footer";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkServicesDetails = (props) => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Dark Services Details`} />
        <Breadcrumb
          title="Service Details"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <SingleService {...props} />
        <Video />
        <Footer DarkSectionBg="template__dark--bg2" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkServicesDetails;
