import React from "react";
import Wrapper from "../../components/UI/Wrapper";
import Header from "../../components/Header/Header";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Footer from "../../components/Footer/Footer";
import About from "../../components/About/About";
import ServicesSection from "../home/ServicesSection";
import Testimonial from "../home/Testimonial";
import TeamSection from "../home/TeamSection";
import FunFact from "../../components/FunFact/FunFact";

const AboutUs = () => {
  return (
    <Wrapper>
      <Header pageTitle={`Ekra | About Us`} />
      <Breadcrumb title="About US" />
      <About />
      <ServicesSection />
      <TeamSection />
      <Testimonial />
      <FunFact />
      <Footer />
    </Wrapper>
  );
};

export default AboutUs;
