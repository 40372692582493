import React from "react";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import FaqSection from "../../home/FaqSection";
import Wrapper from "../../../components/UI/Wrapper";
import Footer from "../../../components/Footer/Footer";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkFaq = () => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | FAQ Dark`} />
        <Breadcrumb
          title="FAQ"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <FaqSection />
        <Footer DarkSectionBg="template__dark--bg2" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkFaq;
