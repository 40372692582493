import React from "react";
import Wrapper from "../../../components/UI/Wrapper";
import Header from "../../../components/Header/Header";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import Footer from "../../../components/Footer/Footer";
import Blog from "../../blog/Blog";
import DarkLayout from "../../../components/UI/DarkLayout";

const DarkBlogPage = () => {
  return (
    <Wrapper>
      <DarkLayout>
        <Header pageTitle={`Ekra | Blog Dark`} />
        <Breadcrumb
          title="Blog"
          DarkBgcolor="template__dark--bg2"
          DarkTextColor="text__color2"
        />
        <Blog DarkCardBg="template__dark--bg3" />
        <Footer DarkSectionBg="template__dark--bg2" />
      </DarkLayout>
    </Wrapper>
  );
};

export default DarkBlogPage;
